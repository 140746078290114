import styled from 'styled-components'
import { typography, spacing, color, mbSize } from '@renderbus/common/theme'
import { H1, H3, Wrapper, H2, Button, Flex, Column, Row } from '@renderbus/common/components'
import Media from '@renderbus/common/theme/media'

import borderImage from '../images/go-cloud/border.png'
import GPUBannerMbBg from '../images/go-cloud/gpu-banner-mobile.png'
import GPUBannerBg from '../images/go-cloud/gpu-banner.png'
export const BannerContainer = styled.div`
  position: relative;
  height: 100vh;
  text-align: center;
  color: white;
  background-image: ${`url(${GPUBannerBg})`};
  background-color: #111;
  background-size: 100% 100%;
  ${Media.lessThan(Media.small)} {
    height: 668px;
    background-image: ${`url(${GPUBannerMbBg})`};
  }
`
export const BannerTitle = styled(H1)`
  padding: ${spacing.small} 0 ${spacing.base};
  font-size: ${typography.h3};
  font-weight: 300;
`
export const BannerSubTitle = styled(H3)`
  max-width: 1400px;
  margin: auto;
  padding-top: 100px;
  font-weight: 300;
  ${Media.lessThan(Media.small)} {
    padding: 30px 1rem 0;
    font-size: ${typography.h4};
  }
`
export const GoCloudBackground = styled.div`
  padding: 200px 0 50px;
  position: relative;
  background: #111;
  ${Media.lessThan(Media.small)} {
    padding: 0;
  }
`
export const IntroWrapper = styled(Wrapper)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 270px;
  width: 1400px;
  background: ${color.background};
  background: rgba(34, 34, 34, 0.6);
  border: 2px solid ${color.primary};
  border-radius: 10px;
  ${Media.lessThan(Media.small)} {
    position: static;
    transform: none;
    min-height: auto;
    margin: -66px auto 50px;
    width: 89.33vw;
    border: 1px solid ${color.primary};
    padding: 40px 1.5rem 44px;
  }
`
export const IntroText = styled.div`
  padding: 20px 100px;
  max-width: 1200px;
  border: 2px solid ${color.primary};
  border-image: url(${borderImage}) 2;
  font-weight: 400;
  text-align: center;
  color: white;
  font-size: 20px;
  font-family: Source Han Sans CN;
  ${Media.lessThan(Media.small)} {
    padding: 0;
    border: none;
    font-size: 12px;
  }
`
export const GoCloudTitle = styled(H2)`
  font-size: ${typography.title};
  font-weight: normal;
  text-align: center;
`
export const GoCloudSubTitle = styled.p`
  margin-bottom: 40px;
  font-size: ${typography.textSmall};
  text-align: center;
  color: ${color.subTitle};
  ${Media.lessThan(Media.small)} {
    margin-bottom: 10px;
  }
`
export const GPUWrapper = styled(Wrapper)`
  background-color: ${({ bgColor }) => bgColor || ''};
  margin-bottom: 70px;
  ${Media.lessThan(Media.small)} {
    margin-bottom: 3.12rem;
  }
`
export const GPUCentent = styled(Flex)`
  width: 1400px;
  margin: 0 auto;
  flex-direction: column;
  align-items: self-start;
  padding-top: 295px;
  ${Media.lessThan(Media.small)} {
    width: unset;
    align-items: center;
    padding-top: 111px;
    img {
      width: 327px;
    }
  }
`

export const Title = styled.h1`
  font-size: 62px;
  font-weight: bold;
  line-height: 1;
  margin: 0;
  span {
    color: #13cb75;
  }

  ${Media.lessThan(Media.small)} {
    font-size: 32px;
  }
`

export const SubTitle = styled.h2`
  margin: 23px 0 0;
  font-size: ${typography.h3};
  font-weight: 400;
  ${Media.lessThan(Media.small)} {
    font-size: ${typography.h4};
  }
`
export const DateTitle = styled.p`
  display: none;
  margin: 0;
  font-size: ${typography.h4};
  ${Media.lessThan(Media.small)} {
    font-size: ${typography.textSmall};
  }
`
export const GPUDesc = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 261px;
  height: 38px;
  line-height: 38px;
  border: 1px solid #14cc76;
  border-radius: 18px;
  font-weight: 400;
  color: #14cc76;
  font-size: 20px;
  text-align: center;
  margin-bottom: 28px;
  ${Media.lessThan(Media.small)} {
    width: 270px;
    height: 28px;
    line-height: 28px;
    font-size: 15px;
    margin-bottom: ${spacing.base};
  }
`
export const GPUPrice = styled.div`
  font-size: ${typography.h3};
  color: #ffffff;
  margin-top: ${spacing.small};
  span {
    font-style: italic;
    color: #14cc76;
    font-size: ${typography.title};
  }
  ${Media.lessThan(Media.small)} {
    font-size: 15px;
    span {
      font-size: 27px;
    }
  }
`
export const GPUDetailBtn = styled(Button)`
  width: 160px;
  height: 40px;
  line-height: 40px;
  background: ${(p) => (p.disabled ? '#999999' : '#14cc76')};
  border-radius: 20px;
  text-align: center;
  color: #ffffff;
  font-weight: 400;
  font-size: 16px;
  margin: 40px 0 0;
  pointer-events: ${(p) => p.disabled && 'none'};
  ${Media.lessThan(Media.small)} {
    width: 120px;
    height: 30px;
    line-height: 30px;
    font-size: ${typography.textSmall};
    margin: ${mbSize(40)} 0 ${mbSize(30)};
  }
`

export const QingyunTitle = styled.h1`
  font-size: 90px;
  margin: 0;
  font-weight: 900;
  font-style: italic;
  color: white;
  text-shadow: 3px 3px #e8471a, -3px -2px green;
  letter-spacing: 0px;
  ${Media.lessThan(Media.small)} {
    font-size: 54px;
  }
`

export const QingyunBr = styled.br`
  ${Media.greaterThan(Media.small)} {
    display: none;
  }
`

export const QingyunColumn = styled(Column)`
  margin: 0 30px;
  padding: unset !important;
  ${Media.lessThan(Media.small)} {
    width: max-content;
    max-width: 89.33vw;
    margin: 10px 20px;
    padding: unset !important;
  }
`

export const QingyunRow = styled(Row)`
  justify-content: center;
  margin: auto -24px;
  &.second {
    margin: auto -24px;
  }
  ${Media.lessThan(Media.small)} {
    width: 100%;
    margin: unset !important;
    &.second {
      margin: unset !important;
    }
  }
`
export const GoCloundWarnTipMask = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 24px;
  transition: transform 0.3s ease-in-out;
  ${Media.lessThan(Media.small)} {
    font-size: 16px;
  }
`

export const GoCloundWarnTipDialogBody = styled.div`
  padding: 80px 106px 106px;
  text-align: center;
  .goclound-content {
    margin: 30px 0;
  }
  ${Media.lessThan(Media.small)} {
    padding: 40px 26px 53px;
    .goclound-content {
      margin-bottom: 20px;
    }
  }
`

export const GoCloundWarnTipDialog = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 34%;
  flex-direction: column;
  background: linear-gradient(20deg, #245737 0%, #222222 100%);
  box-shadow: 0px 10px 18px 2px rgba(255, 255, 255, 0.1);
  opacity: 0.9;
  border-radius: 10px;
  border: 0;
  ${Media.lessThan(Media.small)} {
    top: 27%;
    margin: 0 20px;
    left: 0;
    right: 0;
  }
`

export const GoCloundWarnHeader = styled.div`
  position: relative;
  width: 100%;
  &:after,
  :before {
    content: ' ';
    position: absolute;
    top: 30px;
    right: 30px;
    background: #999999;
    width: 16px;
    height: 1px;
    cursor: pointer;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`
export const GoCloundWarnBtnGroup = styled.div`
  display: flex;
  justify-content: space-around;
  ${Media.lessThan(Media.small)} {
    justify-content: space-between;
  }
`

export const GoCloundWarnBtn = styled(Button)`
  border: 1px solid #13cb75;
  border-radius: 20px;
  padding: 4px 34px;
  font-weight: 400;
  color: #13cb75;
  line-height: 32px;
  font-size: 16px;
  p {
    font-size: 24px;
    color: #ffffff;
    line-height: 36px;
    margin: 50px auto 30px;
  }
  &:hover {
    background: linear-gradient(-90deg, #3fbc2b 0%, #40c570 100%);
    box-shadow: 0px 6px 11px 1px rgba(64, 194, 93, 0.3);
    color: #fff;
  }
  ${Media.lessThan(Media.small)} {
    font-size: 12px;
    padding: 4px 26px;
    line-height: 24px;
    p {
      font-size: 16px;
      line-height: 27px;
      margin: 30px auto 20px;
    }
  }
  @media (max-width: 350px) {
    padding: 4px 12px;
  }
`

export const GoCloundWarnTitle = styled.div`
  font-size: 30px;
  font-weight: bold;
  color: #13cb75;
  line-height: 36px;
  ${Media.lessThan(Media.small)} {
    font-size: 21px;
    line-height: 27px;
  }
`

export const Tip = styled.div`
  color: #cccccc;
  font-size: 16px;
  line-height: 27px;
  text-shadow: 2px 2px 3px rgba(34, 34, 34, 0.5);
  margin-top: 30px;
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(20)};
    line-height: ${mbSize(27)};
    margin-top: 0;
  }
`
